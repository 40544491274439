import { Snackbar } from '@mui/material';
import * as React from 'react';

import Alert from '@mui/material/Alert';
import { IJob } from 'types/job';
import ConfirmSVG from 'components/Svgs/ConfirmIllustration';
import { useSaveStrings } from 'api/hooks';
import ConfirmationDialog, {
  DialogueButtonType,
} from 'components/Feedback/ConfirmationDialog';
import { useJobContext } from 'pages/Job/hooks/jobContext';

interface DialogProps {
  open: boolean;
  job: IJob;
  onClose: () => void;
}

type Stage = 'confirm' | 'loading' | 'success';

const RemoveStringsDialogs = ({ open, job, onClose }: DialogProps) => {
  const {
    stringSelection: { selectedStrings, stopRemovingStrings },
  } = useJobContext();
  const [stage, setStage] = React.useState<Stage>('confirm');
  React.useEffect(() => {
    setStage('confirm');
  }, [open]);
  const {
    mutate: saveStrings,
    status: stringsSaveStatus,
    reset,
  } = useSaveStrings();
  const [numberStringsRemoved, setNumberStringsRemoved] =
    React.useState<number>(0);

  const onSaveSelection = () => {
    setStage('loading');
    return saveStrings(
      {
        jobId: job.id,
        keys: job
          .translations!.map((translation) => translation.key)
          .filter((key) => !selectedStrings.includes(key)),
      },
      {
        onSuccess: () => {
          setNumberStringsRemoved(selectedStrings.length);
          setStage('success');
          stopRemovingStrings();
        },
      },
    );
  };

  const confirmationDialogueButtons: DialogueButtonType[] = [
    {
      title: 'Cancel',
      handleOnClick: onClose,
      buttonProps: {
        color: 'primary',
        variant: 'outlined',
        'aria-label': 'Cancel removing selected strings',
      },
    },
    {
      title: 'Remove strings',
      handleOnClick: () => onSaveSelection(),
      buttonProps: {
        color: 'error',
        variant: 'contained',
        'aria-label': 'Remove selected strings',
      },
    },
  ];

  const successDialogueButtons: DialogueButtonType[] = [
    {
      title: 'Close',
      handleOnClick: onClose,
      buttonProps: {
        color: 'primary',
        variant: 'contained',
        'aria-label': 'Close and return to Job Detail',
      },
    },
  ];

  return (
    <>
      <ConfirmationDialog
        dialogTitle="Remove strings"
        dialogContentText={`Are you sure you want to remove the ${selectedStrings.length} selected string(s) from job ${job.id}?`}
        isOpen={open && stage === 'confirm'}
        onClose={onClose}
        buttons={confirmationDialogueButtons}
      />

      <ConfirmationDialog
        dialogTitle="Strings removed"
        dialogGraphic={<ConfirmSVG />}
        dialogContentText={`You have successfully removed ${numberStringsRemoved} string(s) from job ${job.id}`}
        isOpen={open && stage === 'success'}
        onClose={onClose}
        buttons={successDialogueButtons}
      />

      <Snackbar
        open={stringsSaveStatus === 'error'}
        autoHideDuration={6000}
        onClose={reset}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          Failed to remove strings. Please try again later.
        </Alert>
      </Snackbar>
    </>
  );
};

export default RemoveStringsDialogs;
