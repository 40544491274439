import React, { createContext, ReactNode, useContext } from 'react';
import { useLogin } from 'api/hooks';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';

type AuthContextType = { username: string };
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthenticationProvider');
  }
  return context;
};

type Props = { children: ReactNode };

const AuthenticationProvider = ({ children }: Props) => {
  const { status, data } = useLogin();

  if (status === 'pending') {
    return <FullScreenSpinner message="Authenticating..." />;
  }
  if (status === 'error') {
    return (
      <FullScreenError message="There was an error logging you in. Try refreshing the application" />
    );
  }

  return <AuthContext.Provider value={data!}>{children}</AuthContext.Provider>;
};

export default AuthenticationProvider;
