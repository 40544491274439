import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { worker } from 'mocks/browser';
import { datadogRum } from '@datadog/browser-rum';

import { DATADOG_RUM_CONFIGS } from 'variables';
import App from './App';
import 'normalize.css';

if (import.meta.env.VITE_MOCK_API) {
  worker.start().then();
}

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(<App />);

if (import.meta.env.PROD) {
  const hostConfig = DATADOG_RUM_CONFIGS.find(
    (config) => config.hostname === window.location.host,
  );

  if (hostConfig) {
    datadogRum.init({
      applicationId: hostConfig.applicationId,
      clientToken: hostConfig.clientToken,
      env: hostConfig.hostname,
      site: 'datadoghq.eu',
      service: 'localisation-webapp',
      version: import.meta.env.VITE_DATADOG_VERSION,
      allowedTracingUrls: [`https://${hostConfig.hostname}`], // origins called by your application
      trackResources: true,
      trackLongTasks: true,
      // See: https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#rum-errors
      beforeSend: (event) => {
        if (
          event.type === 'resource' &&
          (event.resource.status_code ?? 0) >= 500
        ) {
          datadogRum.addError(
            `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`,
          );
        }
        return true;
      },
    });
  }
}
