import React, { useEffect, useMemo } from 'react';
import { IDialects } from 'variables';
import usePaginator from 'components/hooks/usePagination';
import { useGetTranslations } from 'api/hooks';

const useSearchListHooks = (
  jobId: string | undefined,
  dialect: keyof IDialects,
  searchText: string | undefined,
  selectedFilter: string[],
) => {
  const [itemsPerPage] = React.useState<number>(10);
  const {
    data: strings,
    isError,
    isFetching,
  } = useGetTranslations(dialect, searchText!, selectedFilter, {
    enabled: searchText !== undefined,
  });
  const stringItems = useMemo(() => Object.entries(strings || []), [strings]);
  const { handlePageChange, ...pagination } = usePaginator(
    stringItems,
    itemsPerPage,
  );

  useEffect(() => {
    if (isFetching) {
      handlePageChange(1);
    }
  }, [handlePageChange, isFetching]);

  const selectableStrings = useMemo(
    () =>
      stringItems.reduce<string[]>((acc, [key, jobStrings]) => {
        const activeJobId = jobStrings.find(
          (jobString) => jobString.activeJobId,
        )?.activeJobId;
        const partOfAnotherJob = activeJobId && activeJobId !== jobId;
        return partOfAnotherJob ? acc : [...acc, key];
      }, []),
    [jobId, stringItems],
  );

  return {
    ...pagination,
    handlePageChange,
    selectableStrings,
    isError,
    isFetching,
  };
};
export default useSearchListHooks;
