import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { QueryStatus } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { IJob } from 'types/job';
import { IJobsResponse } from 'types/api';
import EntriesCounter from 'components/EntriesCounter/EntriesCounter';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import TableView from './TableView';

type Props = {
  status: QueryStatus;
  data: IJobsResponse | undefined;
  totalJobs: number;
  handleSort: (property: keyof IJob) => void;
  order: 'asc' | 'desc';
  orderBy: keyof IJob;
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: `${theme.spacing(4)} !important`,
  textAlign: 'center',
}));

const JobsTable = ({
  status,
  data,
  totalJobs,
  handleSort,
  order,
  orderBy,
}: Props) => {
  const navigate = useNavigate();

  if (status === 'pending') {
    return (
      <StyledDiv>
        <CircularProgress />
        <Typography>Loading...</Typography>
      </StyledDiv>
    );
  }
  if (status === 'error') {
    return (
      <StyledDiv>
        <Typography variant="h6" component="h1">
          Error
        </Typography>
        <Typography>
          There was an error fetching jobs, please try refresh the page.
        </Typography>
      </StyledDiv>
    );
  }

  const jobs = data!;

  const navigateToJob = (job: IJob) => navigate(`/jobs/${job.id}`);

  return (
    <>
      <Grid
        container
        spacing={8}
        justifyContent="space-between"
        alignItems="center"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
        })}
      >
        <Grid item xs={8}>
          <EntriesCounter text="Total Jobs" count={totalJobs} />
        </Grid>
        <Grid item xs={2} sm="auto">
          <Box>
            <Button
              component={RouterLink}
              to="/jobs/createBulkJob"
              variant="contained"
              color="primary"
              fullWidth
              sx={(theme) => ({
                padding: theme.spacing(1),
              })}
            >
              Start Bulk Job
            </Button>
          </Box>
        </Grid>
        <Grid item xs={2} sm="auto">
          <Button
            component={RouterLink}
            to="/jobs/create"
            variant="contained"
            color="primary"
            fullWidth
            sx={(theme) => ({
              padding: theme.spacing(1),
            })}
          >
            Start New Job
          </Button>
        </Grid>
      </Grid>
      <TableView
        jobs={jobs}
        order={order}
        orderBy={orderBy}
        handleSort={handleSort}
        navigateToJob={navigateToJob}
      />
    </>
  );
};

export default JobsTable;
